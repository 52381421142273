import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BsJustify } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  Image,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  Flex,
  Icon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Avatar,
} from "@chakra-ui/react";
import LogoKT from "../../assets/logoKT - transparent.png";
import LogoKTSymbol from "../../assets/logoKT - Symbol.png";
import LogoInnovation from "../../assets/logo-innovation.png";
import ProjectContext from "../../context/ProjectContext";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { RiLogoutBoxLine } from "react-icons/ri";
import { GoPeople } from "react-icons/go";

function Header({ OpenSidebar }) {
  const { company } = useContext(ProjectContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const user = localStorage.getItem("username");
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef(null);
  const employeeId = localStorage.getItem("id");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const roleId = localStorage.getItem("role_id");
  const CompanyLogo = localStorage.getItem("companyImage");
  const profilePicture = localStorage.getItem("profile_picture");
  const token = localStorage.getItem("token");
  const companyId = localStorage.getItem("company_id");
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const logoutSubmit = async () => {
    try {
      // Clear token and status from localStorage
      localStorage.clear();
      // Redirect to the home page after successful logout
      window.location.href = "/";
    } catch (error) {
      console.error("There was an error during logout:", error);
      // Handle error, show error message, or perform any other action
    }
  };

  const handleMenuClick = (action) => {
    if (action === "logout") {
      handleLogout();
    } else if (action === "changePass") {
      handleChangePass();
    } else if (action === "profile") {
      handleProfile();
    }  else if (action === "companyProfile") {
      handleCompanyProfile();
    }
  };

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true); 

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure want to \n Log Out?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setTimeout(() => {
            logoutSubmit();
            setLoading(false); // Set loading state to false after logout operation completes
          }, 2000);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setLoading(false); // Set loading state to false if user cancels the operation
        }
      });
  };

  const handleProfile = () => {
    const url = `/profile/${employeeId}`;
    window.location.href = url;
  };

  const handleCompanyProfile = () => {
    const url = `/company/${companyId}`;
    window.location.href = url;
  };

  const handleChangePass = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Box
      style={{
        padding: "0 30px 0 30px",
        boxShadow: "0 6px 7px -3px rgba(0, 0, 0, 0.35)",
      }}
      display={"flex"}
      alignItems={"center"}
      className="header d-flex z-100 items-center justify-between px-4 sm:px-6 md:px-8 lg:px-10 py-3 md:py-4 lg:py-5"
    >
      <div className="menu-icon">
        <BsJustify
          className="icon w-5 h-5 text-blue-600"
          onClick={OpenSidebar}
          // onClick={onOpen}
        />
      </div>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create your account</DrawerHeader>

          <DrawerBody>{/* <Input placeholder='Type here...' /> */}</DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      {/* Logo */}
      <Box className="header-left" alignItems={"center"}>
        {!company.company_image ? (
        //    <Box
        //    display={"flex"}
        //    flexDirection={"row"}
        //    gap={2}
        //    alignItems={"center"}
        //  >
        //    <Icon as={HiOutlineBuildingOffice2} />
        //    <Text color={"black"} fontSize={"md"} display={{base:"none", md:"block"}}>{company.company_name}</Text>
        //  </Box>]
         <a href="/dashboard">
          <Image
            src={LogoKTSymbol}
            h={{base:"4vh", md:"6vh", lg:"6vh", xl:"8vh"}}
            alt={company.company_name}
          />
        </a>
        ) : (
          <Image
          src={`${process.env.REACT_APP_API_URL}/${CompanyLogo}`}
          h={{base:"4vh", md:"6vh", lg:"6vh", xl:"8vh"}}
          alt={company.company_name}
          onClick={() => window.location.href = "/dashboard"}
        />
        )}
      </Box>

      {/* Profile Dropdown */}
      <div className="header-right flex items-center">
        <div className="inline-flex items-center gap-3">
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="transparent"
              color="#238FBA"
              borderColor="#238FBA"
              border={{ base: 0, md: "2px" }}
              borderRadius="full"
              p={{ base: 0, sm: 6 }}
              _hover={{ color: "#1a6d8e" }}
              _active={{ color: "#1a6d8e" }}
            >
              <Flex direction="row" alignItems="center" gap="3">
                {/* <Icon as={FaUserCircle} boxSize={{ base: 7, md: 6 }} /> */}
                <Avatar
                border={"1px"}
                borderColor={"blue.800"}
                  src={
                    profilePicture
                      ? `${process.env.REACT_APP_API_URL}/${profilePicture}`
                      : "https://zultimate.com/wp-content/uploads/2019/12/default-profile.png"
                  }
                  w={8}
                  h={8}
                />
                {/* Use Chakra UI's responsive display for the Text component */}
                <Text
                  display={["none", "none", "block"]}
                  fontWeight="400"
                  fontSize="md"
                >
                  {user}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem icon={<GoPeople/>} onClick={() => handleMenuClick("profile")}>
                Profil
              </MenuItem>
              {roleId == 2 && (
                <>
                <MenuItem icon={<HiOutlineBuildingOffice2/>} onClick={() => handleMenuClick("companyProfile")}>
                Perusahaan Saya
              </MenuItem>
               <MenuDivider/>
                </>
              )}
              
             
              {/* <MenuItem>
              Help
              </MenuItem> */}
              <MenuItem icon={<RiLogoutBoxLine/>} onClick={() => handleMenuClick("logout")}>
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
    </Box>
  );
}

export default Header;
